import {
    put,
    call,
    takeLatest,
    takeEvery
} from 'redux-saga/effects';

import {
    SET_LOADING,
    SET_LOADING_OFF,
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    FORGOT_PASSWORD
} from '../actions/authActions';
import {
    loginService,
    forgotPasswordService,
} from '../api';

// Get Todos
function* loginSaga(action) {
    try {
        yield put({ type: SET_LOADING });
        const response = yield call(loginService, action.payload);
        if (response.status === 200) {
            yield put({ type: LOGIN_SUCCESS, payload: response.data });
            action.payload.callback();
        }
    } catch (error) {
        action.payload.failedCallback("Username or password were incorrect.");
        yield put({ type: SET_LOADING_OFF })
    }

}

function* forgotPassword(action) {
    try {
        yield put({ type: SET_LOADING });
        const response = yield call(forgotPasswordService, action.payload);
        if (response.status === 200) {
            // alert(response.status)
            action.payload.callback();
        }
    } catch (error) {
        yield put({ type: SET_LOADING_OFF });

    }
}

// Export the saga (todo-saga)
export default function* authSaga() {
    yield takeEvery(LOGIN, loginSaga)
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
    // yield takeEvery(UPLOAD_VIDEO, uploadVideo)
    // yield takeLatest(CREATE_TODO_REQUESTED, createTodo)
    // yield takeEvery(DELETE_TODO_REQUESTED, deleteTodo)
}

