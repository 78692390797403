import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { API_HANDLER } from '../../../axios/axios'
import Sidebar from '../../../components/Sidebar/index'
import { Modal } from 'antd'
export const Category = (props) => {
  const history = useHistory()
  const [modal, setmodal] = useState(false)
  const authState = useSelector((state) => state.auth)
  const [saveID, setsaveID] = useState('')
  const categoryRef = useRef('')
  useEffect(() => {
    getData()
  }, [])

  const onSubmitForm = async (e) => {
    // e.preventDefault()
    // let data = {}
    // for (var i = 0; i < e.target.length - 1; i++) {
    //   data[e.target[i].name] = e.target[i].value
    // }
    // console.log(categoryRef.current.value)
    // return

    let Response = await API_HANDLER(
      'POST',
      `https://young-glade-24121.botics.co/api/v1/categories/`,
      authState.user.key,
      {
        name: categoryRef.current.value,
      }
    )
    console.log(Response, 'data')
    getData()
  }

  const [categories, setcategories] = useState()

  const getData = async () => {
    let Response = await API_HANDLER(
      'GET',
      'https://young-glade-24121.botics.co/api/v1/categories/?limit=1000&offset=0',
      authState.user.key
    )

    setcategories(Response?.data?.results)
    // setTableData(Response.data.results)
    console.log(Response, 'Categories')
  }

  const DeleteUser = async (id) => {
    await setsaveID(id)
    setmodal(!modal)
  }

  const onConfirmDelete = async () => {
    let Response = await API_HANDLER(
      'DELETE',
      `https://young-glade-24121.botics.co/api/v1/categories/${saveID}/`,
      authState.user.key
    )

    console.log('Deleted', Response)
    if (Response?.status === 204) {
      alert('category successfully deleted')
      setmodal(!modal)
    }
    getData()
  }

  return (
    <div className="overflow-hidden">
      <div className=" overflow-hidden w-full mx-auto">
        <div className="w-full min-h-screen">
          <div className="flex items-center">
            <p className="text-lg md:text-xl font-semibold">Categories</p>
          </div>
          <div className="flex space-x-2 flex-col-reverse md:flex-row">
            <div
              // onSubmit={onSubmitForm}
              className={` flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl`}>
              <div className="flex flex-col">
                <div className="flex items-center justify-between space-y-2">
                  <label htmlFor="Categories">New Category</label>
                  <button
                    onClick={onSubmitForm}
                    className="text-primary font-semibold">
                    Save
                  </button>
                </div>
                <input
                  id="Categories"
                  ref={categoryRef}
                  name="name"
                  placeholder="Add Category"
                  autoComplete="off"
                  className="inputStyle"
                />
                <div className="space-y-1 mt-5">
                  {categories?.map((item, index) => (
                    <div className="flex items-center justify-between">
                      <div>{item.name}</div>
                      <button
                        type="submit"
                        className="text-primary font-semibold"
                        onClick={() => DeleteUser(item.id)}>
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {modal === true && (
              <div className="flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl ">
                <div className="text-lg">Are you sure?</div>
                <div>
                  By deleting the category all the videos within the category
                  will be deteled
                </div>
                <div className="flex flex-col items-center justify-center space-y-2 z-10">
                  <button
                    onClick={onConfirmDelete}
                    className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm  rounded-md `}>
                    Yes
                  </button>
                  <button
                    onClick={() => setmodal(!modal)}
                    className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm rounded-md `}>
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
