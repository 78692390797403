import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar/index'
import { ReactMaterialTable } from '../../../components/tables/tables'
import { getUsersList } from '../../../redux/api'
import { API_HANDLER } from '../../../axios/axios'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
export const UserList = () => {
  const [TableData, setTableData] = useState([])
  const history = useHistory()
  const [modal, setmodal] = useState(false)
  const [userID, setuserID] = useState('')
  // const [categories, setcategories] = useState()

  // const getCategory = async (id) => {
  //   let Response = await API_HANDLER(
  //     'GET',
  //     `https://young-glade-24121.botics.co/api/v1/categories/${id}/`,
  //     authState.user.key
  //   )
  //   return Response?.data?.name
  //   // setcategories(Response?.data?.results)
  // }

  var columns = [
    {
      field: 'name',
      title: 'Name',
      cellStyle: {
        whiteSpace: 'nowrap',
        margin:"0 auto"
      },
      render: (rowData) => {
        return (
          <div
            className="text-primary font-semibold cursor-pointer"
            onClick={() => onMoveToPage(rowData)}>
            {rowData.name}
          </div>
        )
      },
    },
    {
      field: 'email',
      title: 'Email',
      cellStyle: {
        whiteSpace: 'nowrap',
        // textAlign: 'center',
      },
    },
    {
      field: 'category_name',
      title: 'Category',
      cellStyle: {
        whiteSpace: 'nowrap',
        // margin: '0 auto',
        // textAlign: 'center',
      },
      // render: (rowData) => {
      //   return <div>{rowData?.category !== null ?  getCategory(rowData?.category) : "" }</div>
      // },
    },
    {
      title: 'Action',
      field: '_blank',
      cellStyle: {
        whiteSpace: 'nowrap',
        // margin: '0 auto',
        // textAlign: 'center',
      },
      render: (rowData) => (
        <button
          className="text-primary font-semibold mx-auto"
          onClick={() => onDeleteUser(rowData.id)}>
          Remove User
        </button>
      ),
    },
  ]

  const authState = useSelector((state) => state.auth)
  useEffect(() => {
    getData()
    
  }, [])

  const getData = async () => {
    let Response = await API_HANDLER(
      'GET',
      'https://young-glade-24121.botics.co/api/v1/users/?limit=1000&offset=0',
      authState.user.key
    ) 
    setTableData(Response.data.results)
    console.log(Response.data.results)
  }

  const onDeleteUser = async (id) => {
    await setuserID(id)
    setmodal(!modal)
  }
  const DeleteUser = async () => {
    let Response = await API_HANDLER(
      'DELETE',
      `https://young-glade-24121.botics.co/api/v1/users/${userID}/`,
      authState.user.key
    )

    getData()
    setmodal(!modal)
  }
  const onMoveToPage = async (data) => {
    history.push(`/admin-dashboard/view-user/${data.id}`)
  }

  return (
    <div className="overflow-hidden w-full mx-auto">
      <div className="w-full min-h-screen">
        <div className="flex items-center">
          <p className="text-lg md:text-xl font-semibold">Users List</p>
        </div>
        {!modal ? (
          <ReactMaterialTable
            columns={columns}
            data={TableData}
            exportBtn={true}
            searchbar={true}
            toolbar={true}
            fontSize="12px"
            pagging={true}
            id="table"
          />
        ) : (
          <div className="flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl ">
            <div className="text-lg">Are you sure?</div>
            <div>By deleting the user all data will be deteled</div>
            <div className="flex flex-col items-center justify-center space-y-2 z-10">
              <button
                onClick={DeleteUser}
                className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm  rounded-md `}>
                Yes
              </button>
              <button
                onClick={() => setmodal(!modal)}
                className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm rounded-md `}>
                No
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
