import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
//import { useHistory } from 'react-router'
// import { FaRegHeart, FaHeart } from 'react-icons/fa'
// import moment from 'moment'
import classes from './style.module.css'
//import Sidebar from '../../components/Sidebar'
// import { GET_VIDEOS, LIKE_VIDEO } from '../../redux/actions/videoActions'
import { Modal, Pagination } from 'antd'
import { API_HANDLER } from '../../axios/axios'
import moment from 'moment'

const AdminDashboard = (props) => {
  //const history = useHistory()
  const [videos, setvideos] = useState([])
  const [totalVideos, settotalVideos] = useState(0)
  const [modal, setmodal] = useState(false)
  const [ErrorMsg, setErrorMsg] = useState('')
  const [paginationPage, setPaginationPage] = useState(1);



  useEffect(() => {
    getData()
  }, [])

  const authState = useSelector((state) => state.auth)

  const getData = async () => {
    let Response = await API_HANDLER(
      'GET',
      'https://young-glade-24121.botics.co/api/v1/approval-videos/?limit=6&offset=0*6',
      authState.user.key
    )
    settotalVideos(Response?.data?.count)
    
    // const sort_result = Response?.data?.results.sort((a, b) => {
    //   console.log("lksjflkdjsf", Number(a.title.replace(/\D/g, "")))
    //   return Number(a.title.replace(/\D/g, "")) < Number(b.title.replace(/\D/g, "")) ? -1 : 1; 
      
    // });

      //setvideos(sort_result)
      console.log("data", Response?.data?.results)
      setvideos(Response?.data?.results)
      setPaginationPage(1)

  }

  const onAcceptVideo = async (id) => {
    let Response = await API_HANDLER(
      'PATCH',
      `https://young-glade-24121.botics.co/api/v1/approval-videos/${id}/`,
      authState.user.key,
      {
        is_published: true,
      }
    )
    getData()
    // setvideos(Response?.data?.results)
    console.log(Response, 'Accepted')
    if (Response?.data?.is_published) {
      setErrorMsg({
        title: 'Success',
        des: 'Success: video successfully approved.',
      })
      setmodal(!modal)
    }
    if (Response?.response?.data?.detail) {
      setErrorMsg({
        title: 'Error',
        des: 'Error: No video found.',
      })
      setmodal(!modal)
    }
  }

  const onRejectVideo = async (id) => {
    let Response = await API_HANDLER(
      'DELETE',
      `https://young-glade-24121.botics.co/api/v1/approval-videos/${id}/`,
      authState.user.key
      // {
      //   is_rejected: true,
      // }
    )
    getData()
    console.log(Response, 'Rejected')
    if (Response.data.is_published === false) {
      setErrorMsg({
        title: 'Success',
        des: 'Success: video successfully rejected.',
      })
      setmodal(!modal)
    }
    if (Response?.response?.data?.detail) {
      setErrorMsg({
        title: 'Error',
        des: 'Error: No video found.',
      })
    }
    // if(Response.response)
    // console.log(Response.data.results, 'Video data')
    // setvideos(Response?.data?.results)
  }

  const onEnterPage = async (page) => {
    console.log(page)
    console.log(
      'FOR PAGE https://young-glade-24121.botics.co/api/v1/approval-videos/?limit=6&offset=',
      page
    )

    let Response = await API_HANDLER(
      'GET',
      `https://young-glade-24121.botics.co/api/v1/approval-videos/?limit=6&offset=${(page-1)*6}`,
      authState.user.key
    )

    // const sort_result = Response?.data?.results.sort((a, b) => {
    //   return Number(a.title.replace(/\D/g, "")) < Number(b.title.replace(/\D/g, "")) ? -1 : 1;
    // });

    //   setvideos(sort_result)

    //console.log(Response.data)
    //console.log('GETTING ', Response?.data?.results.length)
    setvideos(Response?.data?.results)
    setPaginationPage(page)
  }

  return (
    <div className="w-full overflow-hidden mx-auto min-h-screen">
      <div className="w-full flex flex-col">
        <div className="flex items-center">
          <p className="text-lg md:text-xl font-semibold ">
            Approve video request
          </p>
        </div>
        <div className="flex flex-col px-8 md:px-10">
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-10 md:gap-16 py-5">
              {videos &&
                videos.length > 0 &&
                videos.map((v) => {
                  return (
                    <div className="" key={v.uuid}>
                      <div className="card ">
                        <div className="card-image p-4">
                          <video
                            src={`${v.video_file}`}
                            // preload="metadata"
                            // poster='https://images.unsplash.com/photo-1453728013993-6d66e9c9123a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8cGhvdG9ncmFwaGVyfGVufDB8fDB8fA%3D%3D&w=1000&q=80'
                            controls
                            className="w-full h-32"
                            type="video/mp4"
                          />
                        </div>
                        <footer className="card-footer is-flex -mb-6 ">
                          <span className="card-footer-item">
                            <p className="w-full space-y-2 mt-2">
                              <div className="flex space-x-1 text-xs items-center justify-between">
                                <div className="font-semibold">Name: </div>
                                <span>{v.user_name}</span>
                              </div>
                              <div className="flex space-x-1 text-xs items-center justify-between">
                                <div className="font-semibold">Category: </div>
                                {console.log(v,"test")}
                                <span>{v.category_name}</span>
                              </div>
                              <div className="flex space-x-1 text-xs items-center justify-between">
                                <div className="font-semibold">Created At: </div>
                                <span>{moment(v.created_at).format('DD-MM-YYYY')}</span>
                              </div>
                              <div className="flex flex-col text-left space-y-1 text-xs ">
                                <div className="font-semibold">
                                  Description:{' '}
                                </div>
                                <span>
                                  {v?.description.length > 100
                                    ? v.description.substr(0, 100).concat('...')
                                    : v.description}
                                </span>
                              </div>
                              <div className="flex space-x-2 lg:space-x-4 w-full mt-5">
                                <button
                                  className={`${classes.BtnO} w-1/2 flex items-center justify-center h-8 text-sm bg-white rounded-sm `}
                                  onClick={() => onRejectVideo(v.id)}>
                                  Reject
                                </button>
                                <button
                                  onClick={() => onAcceptVideo(v.id)}
                                  className={`${classes.Btn} w-1/2 flex items-center justify-center h-8 text-sm bg-white rounded-sm `}>
                                  Approve
                                </button>
                              </div>
                            </p>
                          </span>
                        </footer>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className="flex items-center justify-center my-10">
            <Pagination
              total={totalVideos > 0 ? totalVideos : 1}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              onChange={onEnterPage}
              defaultPageSize={6}
              current={paginationPage}
            />
          </div>
        </div>
      </div>
      <Modal
        // title="Purpose"
        footer={null}
        visible={modal}
        // destroyOnClose={true}
        // width="20%"
        centered={true}
        // bodyStyle={{ padding: 0, height: '550px', minHeight: '200px' }}
        onOk={() => setmodal(!modal)}
        onCancel={() => setmodal(!modal)}>
        <div className="flex flex-col  space-y-4 w-full  p-8 shadow-xl ">
          <div className="text-xl font-semibold">{ErrorMsg?.title}</div>
          <div>{ErrorMsg?.des}</div>
          <div className="flex flex-col items-center justify-center space-y-2 z-10">
            <button
              onClick={() => setmodal(!modal)}
              className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/3 flex items-center justify-center h-8 text-sm rounded-md `}>
              close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
export default AdminDashboard
