import axios from 'axios'
// import { useSelector } from 'react-redux'
export const API_HANDLER = function (method, route, token, data) {
  // const token = localStorage.getItem("userToken");
  // console.log(token, 'token')
  // console.log(data)
  const headers = {
    Authorization: `Token ${token}`, 
  }
  return axios({
    method,
    url: route,
    headers,
    data:data,
  })
    .then((response) => { 
      return response
    })
    .catch((response) => {
      return response
    })
}
