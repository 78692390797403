import React, { useEffect } from 'react'
import MainScreen from '../assets/img/splash_logo.png'
import { useHistory } from 'react-router-dom'

const Splash = (props) => {
  const history = useHistory()

  useEffect(() => {
    setTimeout(() => {
      history.push('/member-dashboard/login')
    }, 2000)
  }, [history])
  return (
    <div className="container ivod-splash is-fluid min-h-screen flex items-center justify-center">
      <img
        src={MainScreen}
        className="w-16 h-16 md:w-24 md:h-24 object-cover object-center"
      />
    </div>
  )
}

export default Splash
