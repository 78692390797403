import defaultAxios from 'axios'
import Platform from 'react-platform-js'

import config from '../../config'

const axios = defaultAxios.create({
  baseURL: config.BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

export const uploadVideoService = async (data) => {
  axios.defaults.headers['Authorization'] = 'Token ' + data.user.key
  axios.defaults.headers['content-type'] = 'multipart/form-data'
  axios.defaults.headers['Access-Control-Allow-Origin'] = '*'
  var fd = new FormData()
  console.log(data.video_file.type)
  const lowerCase = String(Platform.OS).toLowerCase()
  const os = lowerCase.replace(' ', '')
  fd.append('video_file', data.video_file, `${os}_featured.mp4`)
  fd.append('user', data.user.user.id)
  fd.append('description', data.description)
  fd.append('title', data.title)

  return axios.request({
    method: 'post',
    url: 'api/v1/videos/',
    data: fd,
  })
}

export const loginService = async (data) => {
  return axios.request({
    method: 'post',
    url: 'rest-auth/login/',
    data: {
      email: data.email,
      password: data.password,
    },
  })
}

export const getVideosService = async (data) => {
  
  axios.defaults.headers['Authorization'] = 'Token ' + data.user.key
  return axios.request({
    method: 'get',
    url: `/api/v1/videos/?user__id=${data.user.user.id}&limit=${data.limit}`,
    // params: { user__id: data.user.user.id },
  })
}

export const updateVideosUrlsService = async (data) => {
  axios.defaults.headers['Authorization'] = 'Token ' + data.user.key
  return axios.request({
    method: 'get',
    url: `/api/v1/video-urls/?user__id=${data.user.user.id}&limit=${data.limit}`,
    // params: { user__id: data.user.user.id },
  })
}

export const likeVideoService = async (data) => {
  axios.defaults.headers['Authorization'] = 'Token ' + data.user.key
  return axios.request({
    method: 'post',
    url: 'api/v1/video-likes/',
    data: {
      video: data.video,
      user: data.user.user.id,
    },
  })
}

export const forgotPasswordService = async (data) => {
  console.log(data)
  return axios.request({
    method: 'post',
    url: 'rest-auth/password/reset/',
    data: {
      email: data.email,
    },
  })
}

export const getUsersList = async (data) => {
  axios.defaults.headers['Authorization'] = 'Token ' + data.user.key
  return axios.request({
    method: 'get',
    url: 'api/v1/users/',
    // data: {
    //     email: data.email
    // }
  })
}
