import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

const Modal = (props) => {
  const [isActive, setIsActive] = useState(false)
  const [message, setMessage] = useState('')
  let classes = classNames({
    modal: true,
    'is-active': isActive,
  })

  useEffect(() => {
    console.log('props ', props)
    setIsActive(props.showModal)
    setMessage(props.message)
  }, [props])

  return (
    <div className={classes} onClick={props.handleModalClose}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className="box">
          {props.showProgressBar && (
            <progress className="progress is-small is-info" max="100">
              60%
            </progress>
          )}
          {message && message}
        </div>
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={props.handleModalClose}></button>
    </div>
  )
}

export default Modal
