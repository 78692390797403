import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import Sidebar from '../Sidebar'
import { AdminSidebarData, MemberSidebarData } from '../Sidebar/SidebarData'
import SubMenu from '../Sidebar/SubMenu' 

export const PanelHandler = ({ children }) => {
  const history = useHistory()
  const menu = useSelector((state) => state.menu)
  const authState = useSelector((state) => state.auth)

  return (
    <div className="flex bg-gray-200 ">
      <div className="hidden lg:block lg:w-2/12 bg-primary pb-5">
        {authState.user.user.is_admin
          ? AdminSidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />
            })
          : MemberSidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />
            })}
      </div>
      <div className="block lg:hidden z-40">{menu.visible && <Sidebar/>}</div>
      <div className="w-full lg:w-10/12 xs:px-4 sm:px-4 md:px-8 2xl:px-8 py-4">{children}</div>
    </div>
  )
}
