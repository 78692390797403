import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FORGOT_PASSWORD } from '../redux/actions/authActions'
import { useHistory } from 'react-router-dom'

const ForgotPassword = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [flag, setFlag] = useState(false)
  const [message, setMessage] = useState(null)
  const [loading, setloading] = useState(false)

  const handleSubmit = (_) => {
    if (loading) return
    setloading(true)
    if (email)
      dispatch({
        type: FORGOT_PASSWORD,
        payload: { email, callback: onSuccess },
      })
  }

  const onSuccess = (_) => {
    // history.push("/record");
    setloading(false)
    setFlag(true)
    setMessage(
      'If you are a registered user, we have sent password recovery link to your email. Please check.'
    )
  }

  return (
    <>
      <div
        style={{ background: '#F9F9F9' }}
        className="w-full flex items-center justify-center md:min-h-screen  ">
        <div className="w-full md:w-1/3 bg-white md:pb-12 rounded-md">
          <div className="hidden md:block ivod-box md:h-10 text-xs text-transparent">
            Test
          </div>

          <div className="flex md:block flex-col justify-center   min-h-screen md:min-h-full md:pt-16">
            <img
              src={require(`../assets/img/logo.png`).default}
              alt=""
              className="block md:hidden mx-auto w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 object-contain"
            />
            <div className="w-full text-center mb-5">
              <div className="login-text ">Reset Password</div>
            </div>
            {message ? (
              <div className="w-full py-2">
                <div className="w-8/12 md:w-7/12 mx-auto">{message}</div>
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <div className="w-8/12 md:w-7/12">
                  <div className="field">
                    Email
                    <p className="control has-icons-left has-icons-right">
                      <input
                        onChange={(evt) => setEmail(evt.target.value)}
                        className="input"
                        type="email"
                        placeholder="Email"
                        value={email}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center justify-center mt-2">
              <div className="w-8/12 md:w-7/12">
                {message ? (
                  <button
                    onClick={() => history.push(`/member-dashboard/login`)}
                    className="button is-fullwidth ivod-login-button">
                    Ok
                  </button>
                ) : (
                  <button
                    onClick={handleSubmit}
                    disabled={loading}
                    className="button is-fullwidth ivod-login-button">
                    Confirm
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword
