import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as ReduxProvider } from 'react-redux'

import './index.css'
import App from './App'
import UploadVideo from './screens/UploadVideo'
import Login from './screens/Login'
import AdminLogin from './screens/AdminLogin'
import SplashScreen from './screens/SplashScreen'
import ForgotPassword from './screens/ForgotPassword'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Navbar from './components/Navbar'
import { store, persistor } from './redux/store'
import { withAppInstall } from './components/AppInstall'
import { PrivateRoute, PublicRoute } from './components/Navigator'
import NoMatch from './screens/NoMatch'
import MemberDashboard from './screens/members/MemberDashboard'
import AdminDashboard from './screens/admin/AdminDashboard'
import Logout from './screens/Logout'
import Profile from './screens/members/Profile'
import BackCamera from './screens/members/BackCamera'
import { AddUser } from './screens/admin/Components/addUser'
import { UserList } from './screens/admin/Components/userList'
import { Notifications } from './screens/admin/Components/notifications'
import { Category } from './screens/admin/Components/category'
import { ViewUser } from './screens/admin/Components/viewUser'
import AdminSignUP from './screens/AdminRegister'

const Main = (props) => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <Router>
            {/* <Navbar /> */}
            <Switch>
              <Route exact path="/">
                <SplashScreen />
              </Route>
              <PublicRoute exact path="/admin-dashboard/login">
                <AdminLogin />
              </PublicRoute>
              <PublicRoute exact path="/admin-dashboard/sign-up">
                <AdminSignUP />
              </PublicRoute>

              <PublicRoute exact path="/member-dashboard/login">
                <Login />
              </PublicRoute>
              <Route path="/member-dashboard/forgot-password">
                <ForgotPassword />
              </Route>
              <PrivateRoute exact path="/record" component={App} />

              <PrivateRoute
                exact
                path="/member-dashboard"
                component={MemberDashboard}
              />

              <PrivateRoute
                exact
                path="/admin-dashboard"
                component={AdminDashboard}
              />
              <PrivateRoute
                exact
                path="/admin-dashboard/add-user"
                component={AddUser}
              />

              <PrivateRoute
                exact
                path="/admin-dashboard/users-list"
                component={UserList}
              />

              <PrivateRoute
                exact
                path="/admin-dashboard/notifications"
                component={Notifications}
              />
              <PrivateRoute
                exact
                path="/admin-dashboard/categories"
                component={Category}
              />
              <PrivateRoute
                exact
                path="/admin-dashboard/view-user/:id"
                component={ViewUser}
              />
              <PrivateRoute
                path="/admin-dashboard/back-camera"
                component={BackCamera}
              />
              <PrivateRoute path="/upload" component={UploadVideo} />

              <PrivateRoute path="/logout" component={Logout} />
              <PrivateRoute
                path="/member-dashboard/profile"
                component={Profile}
              />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Router>
        </React.StrictMode>
      </PersistGate>
    </ReduxProvider>
  )
}

export default withAppInstall(Main)
