import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router'

import config from '../../config'

const BackCamera = (props) => {
  const [mediaRecorder, setMediaRecorder] = useState({})
  const [isRecording, setIsRecording] = useState(false)
  const [showRear, setShowRear] = useState(false)
  const recorderRef = useRef()
  const playerRef = useRef()
  const history = useHistory()
  /**
   * Initialize the MediaRecorder on component mount
   */
  useEffect(() => {
    console.log('reinitializing..')
    initializeMediaRecorder()
  }, [])
 
  /**
   * Upon MedaRecorder being set, monitor the following events
   */
  useEffect(() => {
    let chunks = []

    mediaRecorder.ondataavailable = (e) => chunks.push(e.data)

    mediaRecorder.onstop = (e) => {
      let blob = new Blob(chunks, { type: 'video/mp4' })
      chunks = []

      let url = (window.URL || window.webkitURL).createObjectURL(blob)
    //   handleVideoRecorder(blob)
      // uploadVideo(blob);
      // setPlaybackPreview(url);
      // createDownloadLink(url);
    }

    mediaRecorder.onerror = (e) => {
      console.log('Error recording stream')
      console.log(e.error)
      alert(e.error)
    }

    console.log('MediaRecorder ready')
  }, [mediaRecorder])

  // const createDownloadLink = url => {
  //   const link = <a href={url} download="Recording.mp4">Download</a>;
  //   const linkContainer = document.getElementById('download-link');
  //   ReactDOM.render(link, linkContainer);
  // }

  /**
   * Helper function to console out change in state of the MediaRecorder
   */
  useEffect(() => {
    console.log(`MediaRecorder state: ${mediaRecorder.state}`)
  }, [mediaRecorder.state])

  /**
   * Start recording the stream
   */
  const start = async () => {
    if (mediaRecorder.state === 'recording') return
    mediaRecorder.start()
    setIsRecording(true)
  }

  /**
   * Stop recording the stream
   */
  const stop = async () => {
    if (mediaRecorder.state === 'inactive') return
    mediaRecorder.stop() 
    console.log(`MediaRecorder state: ${mediaRecorder.state}`)
    setIsRecording(false)
    await initializeMediaRecorder()
  }

  /**
   * Set the playback player's source to the url of the newly recorderd stream
   * @param {string} url
   */
  const setPlaybackPreview = (url) => {
    if (!playerRef.current) return
    console.log(`Playback URL: ${url}`)
    playerRef.current.src = url
  }

  /**
   * Get a media device stream (webcam)
   */
  const getStream = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            facingMode: { exact: 'environment' },
          },
        })
        console.log('Stream fetched and rear camera? ', showRear)
        resolve(stream)
      } catch (err) {
        console.log('Error in fetching stream')
        alert(err)

        reject(err)
      }
    })
  }

  /**
   * Set the live stream retrieved from the media device
   * to the designated player to preview
   * @param {object} stream
   */
  const setRecordingStreamPreview = (stream) => {
    if (!recorderRef.current) return
    recorderRef.current.srcObject = stream
    console.log('recordref ', recorderRef.current.srcObject)
  }

  /**
   * Create MediaRecorder object from a given stream
   * @param {object} stream
   */
  const createMediaRecorder = (stream) => {
    return new Promise((resolve, reject) => {
      try {
        const mediaRecorder = new MediaRecorder(stream)
        console.log('New MediaRecorder created')
        resolve(mediaRecorder)
      } catch (err) {
        console.log('Error in creating new MediaRecorder')
        alert(err)

        reject(err)
      }
    })
  }

  /**
   * Initialize MediaRecorder
   */
  const initializeMediaRecorder = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const stream = await getStream()
        console.log(stream)
        setRecordingStreamPreview(stream)
        const mediaRecorder = await createMediaRecorder(stream)
        setMediaRecorder(mediaRecorder)
        resolve(mediaRecorder)
      } catch (err) {
        console.log(
          'Error in initializing MediaRecorder of fetching media devices stream'
        )
        alert(err)

        reject(err)
      }
    })
  }

  const handleFileChange = (evt) => {
    history.push({
      pathname: '/upload',
      state: {
        video_file: evt.target.files[0],
      },
    })
  }

  const handleVideoRecorder = (blob) => {
    history.push({
      pathname: '/upload',
      state: {
        video_file: blob,
        video_blob: blob,
      },
    })
  }

  const handleRearCamera = (_) => {
    history.push('/record')
  }

  return (
    <>
      <div className="columns is-centered is-mobile">
        <div className="column has-text-centered">
          <video
            className="container is-widescreen"
            ref={recorderRef}
            autoPlay
            playsInline
            muted
          />
          <div className="level is-mobile">
            <div className="level-item has-text-centered">
              <div className="player-uttons">
                <button
                  className="button is-rounded is-danger"
                  onClick={isRecording ? stop : start}>
                  <span className="icon">
                    {isRecording ? (
                      <i className="fa fa-stop"></i>
                    ) : (
                      <i className="fa fa-video-camera"></i>
                    )}
                  </span>
                </button>
                <button
                  className="button is-rounded is-warning ml-2"
                  onClick={handleRearCamera}>
                  <span className="icon">
                    <i className="fa fa-refresh"></i>
                    {/* {isRecording ?
                  <i className="fa fa-stop"></i>
                  :
                  <i className="fa fa-video-camera"></i>
                } */}
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="level is-mobile">
            <div className="level-item has-text-centered">
              <div className="file is-primary">
                <label className="file-label">
                  <input
                    onChange={handleFileChange}
                    className="file-input"
                    type="file"
                    name="video"
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fa fa-cloud-upload"></i>
                    </span>
                    <span className="file-label">Upload Video</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BackCamera
