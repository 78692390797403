import React from 'react'
import { useHistory } from 'react-router-dom'

import './assets/css/main.css'
import './App.css'
// Component
import MediaRecorderCapture from './components/MediaRecorderCapture'

function App() {
  const history = useHistory()
  const handleFileChange = (evt) => {
    history.push({
      pathname: '/upload',
      state: {
        video_file: evt.target.files[0],
      },
    })
  }

  const handleVideoRecorder = (blob) => {
    history.push({
      pathname: '/upload',
      state: {
        video_file: blob,
        video_blob: blob,
      },
    })
  }

  return (
    <MediaRecorderCapture
      handleFileChange={handleFileChange}
      handleVideoRecorder={handleVideoRecorder}
    />
  )
}

export default App
