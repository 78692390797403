// Import the redux-saga/effects
import { put, call, takeLatest, takeEvery } from 'redux-saga/effects'

// Import all actions and api's
import {
  SET_LOADING,
  SET_LOADING_OFF,
  GET_VIDEOS,
  GET_VIDEOS_REQUESTED,
  UPLOAD_VIDEO,
  LIKE_VIDEO,
  UPDATE_VIDEOS_URLS,
  GET_VIDEOS_FOR_UPDATE_URL,
  LOAD_MORE_VIDEOS,
  SET_LOAD_MORE_VIDEOS,
} from '../actions/videoActions'

import { uploadVideoService, getVideosService, likeVideoService, updateVideosUrlsService } from '../api'

// Get Todos
function* getVideos(action) {
  try {
    yield put({ type: SET_LOADING })
    const response = yield call(getVideosService, action.payload)
    if (response.status === 200) {
      yield put({ type: GET_VIDEOS_REQUESTED, payload: response.data })
    }
  } catch (error) {
    yield put({ type: SET_LOADING_OFF })
  }
}
// Load More Todos
function* loadMoreVideos(action) {
  try {
    yield put({ type: SET_LOADING })
    const response = yield call(getVideosService, action.payload)
    if (response.status === 200) {
      yield put({ type: SET_LOAD_MORE_VIDEOS, payload: response.data })
    }
  } catch (error) {
    yield put({ type: SET_LOADING_OFF })
  }
}

function* updateVideosUrls(action) {
  try {
    yield put({ type: SET_LOADING })
    const response = yield call(updateVideosUrlsService, action.payload) 
    if (response.status === 200) {
      yield put({ type: UPDATE_VIDEOS_URLS, payload: response.data })
    }
  } catch (error) {
    yield put({ type: SET_LOADING_OFF })
  }
}

function* uploadVideo(action) {
  try {
    yield put({ type: SET_LOADING })
    const response = yield call(uploadVideoService, action.payload)
    if (response.status === 201) {
      action.payload.successCallback()
    }
  } catch (error) {
    yield put({ type: SET_LOADING_OFF })
    action.payload.failureCallback()
  }
}

function* likeVideo(action) {
  try {
    const response = yield call(likeVideoService, action.payload)
    if (response.status === 201) {
      yield put({ type: GET_VIDEOS, payload: action.payload })
    }
  } catch (error) {
    yield put({ type: SET_LOADING_OFF })
  }
}

// Export the saga (todo-saga)
export default function* videoSaga() {
  yield takeEvery(GET_VIDEOS, getVideos)
  yield takeEvery(LOAD_MORE_VIDEOS, loadMoreVideos)
  yield takeEvery(GET_VIDEOS_FOR_UPDATE_URL, updateVideosUrls)
  yield takeEvery(UPLOAD_VIDEO, uploadVideo)
  yield takeEvery(LIKE_VIDEO, likeVideo)

  // yield takeEvery(DELETE_TODO_REQUESTED, deleteTodo)
}
