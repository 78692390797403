import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Sidebar from '../../../components/Sidebar/index'
import { useParams } from 'react-router-dom'
import { API_HANDLER } from '../../../axios/axios'
import { useSelector } from 'react-redux'
export const ViewUser = (props) => {
  const history = useHistory()
  const { id } = useParams()
  const authState = useSelector((state) => state.auth)
  const [userData, setuserData] = useState('')
  const [password, setpassword] = useState('')
  const [modal, setmodal] = useState(false)
  const [category, setcategory] = useState('')
  useEffect(() => {
    getUser()
    getData()
  }, [])
  const [categories, setcategories] = useState()
  // const [userCategories, setuserCategories] = useState([])
  const getData = async () => {
    let Response = await API_HANDLER(
      'GET',
      'https://young-glade-24121.botics.co/api/v1/categories/?limit=1000&offset=0',
      authState.user.key
    )

    setcategories(Response?.data?.results)
  }

  const getUser = async () => {
    let Response = await API_HANDLER(
      'GET',
      `https://young-glade-24121.botics.co/api/v1/users/${id}/`,
      authState.user.key
    )
    setuserData(Response?.data)
    console.log(Response, 'Response')
    // console.log(Response, 'Hello')
  }

  const updateUserPassword = async (e) => {
    e.preventDefault()
    if (password === '') {
      alert('please enter password to reset it.')
    }
    let Response = await API_HANDLER(
      'PATCH',
      `https://young-glade-24121.botics.co/api/v1/users/${id}/`,
      authState.user.key,
      { password: password.trim() }
    )
    if (Response.status === 200) {
      alert('Password successfully reset.')
    }
    setuserData(Response?.data)
    console.log(Response)
  }

  const updateUserCategory = async (e) => {
    e.preventDefault()
    console.log(category)
    let Response = await API_HANDLER(
      'PATCH',
      `https://young-glade-24121.botics.co/api/v1/users/${id}/`,
      authState.user.key,
      { category: category }
    )
    if (Response.status === 200) {
      alert('Category successfully updated.')
    }
    // setuserData(Response?.data)
    console.log(Response)
  }

  const onDeleteVideo = async (e, vid) => {
    e.preventDefault()
    let Response = await API_HANDLER(
      'DELETE',
      `https://young-glade-24121.botics.co/api/v1/videos/${vid}/`,
      authState.user.key
    )
    getUser()
    alert('Selected video is successfully deleted')
  }

  const DeleteUser = async () => {
    let Response = await API_HANDLER(
      'DELETE',
      `https://young-glade-24121.botics.co/api/v1/users/${id}/`,
      authState.user.key
    )

    if (Response.status === 204) {
      await alert('user successsfully deteted')
      history.push('/admin-dashboard/users-list')
    } 
  }

  return (
    <div className="overflow-hidden">
      <div className="overflow-hidden w-full mx-auto">
        <div className="column min-h-screen">
          <div className="flex flex-row items-center justify-between w-full md:w-1/3   mb-2">
            <div className="text-lg md:text-xl font-semibold  ">
              View User
            </div>
            <button
              onClick={() => setmodal(!modal)}
              className="h-7 bg-primary text-white border border-primary px-3 rounded-sm duration-1000 hover:text-primary hover:bg-white hover:border-primary">
              Remove User
            </button>
          </div>
          {!modal ? (
            <form
              // onSubmit={onSubmitForm}
              className="flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl ">
              <div className="flex items-center space-x-1">
                <span className="font-semibold">Name:</span>
                <span>{userData?.name}</span>
              </div>
              <div className="flex items-center space-x-1">
                <span className="font-semibold">Email:</span>
                <span>{userData?.email}</span>
              </div>

              <div className="flex items-center justify-between space-y-2">
                <label htmlFor="password">Password</label>
                <button
                  onClick={updateUserPassword}
                  className="text-primary font-semibold">
                  Reset
                </button>
              </div>
              <input
                id="Password"
                type="password"
                name="password"
                onChange={(e) => setpassword(e.target.value)}
                placeholder="●●●●●●●●"
                autoComplete="off"
                className="inputStyle"
              />

              <div className="flex flex-col">
                <div className="flex items-center justify-between space-y-2">
                  <label htmlFor="Categories">Category</label>
                  <button
                    onClick={updateUserCategory}
                    className="text-primary font-semibold">
                    Save
                  </button>
                </div>
                <select
                  name="category"
                  onChange={(e) => setcategory(e.target.value)}
                  className="inputStyle"
                  defaultValue={category}>
                  {categories?.map((item, index) => (
                    <option
                      key={index}
                      className="md:h-5 text-xs sm:text-base md:text-lg"
                      value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col space-y-1 mt-5">
                {userData?.user_videos?.map((item, index) => (
                  <div className="flex items-center justify-between">
                    <div>{item?.title}</div>
                    <button
                      onClick={(e) => onDeleteVideo(e, item.id)}
                      // type="submit"
                      className="text-primary font-semibold">
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </form>
          ) : (
            <div
              // onSubmit={onSubmitForm}
              className="flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl ">
              <div className="text-lg">Are you sure?</div>
              <div>By deleting the user all data will be deteled</div>
              <div className="flex flex-col items-center justify-center space-y-2 z-10">
                <button
                  onClick={DeleteUser}
                  className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm  rounded-md `}>
                  Yes
                </button>
                <button
                  onClick={() => setmodal(!modal)}
                  className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm rounded-md `}>
                  No
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
