import { spawn } from 'redux-saga/effects'

// Sagas
import videoSaga from './videoSagas';
import authSaga from './authSagas';

// Export the root saga
export default function* rootSaga() {
    console.log("Hello From Redux-Saga!")

    yield spawn(videoSaga);
    yield spawn(authSaga);
}