import React, { useState, useRef, Text, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import '../assets/css/main.css'
import { UPLOAD_VIDEO } from '../redux/actions/videoActions'
import Sidebar from '../components/Sidebar'
import Modal from '../components/Modal'

const UploadVideo = (props) => {
  const history = useHistory()
  const location = useLocation()
  const URL = window.URL || window.webkitURL
  const dispatch = useDispatch()
  const videoState = useSelector((state) => state.video)
  const authState = useSelector((state) => state.auth)
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState(null)
  const [showProgressBar, setShowProgressBar] = useState(false)
  const description = useRef()
  const title = useRef()
  // console.log('location.state.video_file', location.state.video_file)
  const video_url = URL.createObjectURL(location?.state?.video_file)

  useEffect(() => {
    // getData()
  }, [])

  const handleUploadVideo = (_) => {
    // console.log(location.state.video_file, 'test 202')
    let video_file = location.state.video_file
    dispatch({
      type: UPLOAD_VIDEO,
      payload: {
        video_file,
        user: authState.user,
        description: description.current.value,
        title: title.current.value,
        successCallback: handleUploadVideoSuccess,
        failureCallback: handleUploadVideoFailure,
      },
    })
    setMessage(null)
    setShowModal(true)
    setShowProgressBar(true)
  }

  const handleUploadVideoSuccess = (_) => {
    //   stop progress bar
    setShowProgressBar(false)
    // stop modal
    setShowModal(false)
    // send user to dashboard
    history.push('/member-dashboard')
  }

  const handleUploadVideoFailure = (_) => {
    // stop progress bar
    setShowProgressBar(false)

    // show error message
    setMessage('MMH: Something went wrong!')
  }

  const handleModalClose = (_) => {
    setShowModal(false)
  }

  const handleDelete = (_) => {
    history.push('/member-dashboard')
  }
  // const getData = async () => {
  //   let Response = await API_HANDLER(
  //     'GET',
  //     'https://young-glade-24121.botics.co/api/v1/categories/?limit=1000&offset=1',
  //     authState.user.key
  //   )

  // }
  return (
    <section className="space-y-3">
      <Modal
        showModal={showModal}
        message={message}
        showProgressBar={showProgressBar}
        handleModalClose={handleModalClose}
      />
      <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-semibold">
        Upload Video
      </p>
      <div className="w-full md:w-1/2 space-y-3 bg-white p-8 rounded-md">
        <video
          className="w-full"
          controls
          autoPlay
          webkit-playsInline="true"
          playsInline="true"
          webkit-playsinline="true"
          playsinline="true"
          src={video_url}></video>

        {/* <p className="text-lg md:text-xl">Category</p>
        <div className="w-full">

        </div> */}
        <p className="text-lg md:text-xl">Title</p>
        <div className="w-full">
          <input
            className="w-full outline-none p-2 border border-gray-200 bg-gray-50"
            placeholder="e.g. Write Title"
            ref={title}></input>
        </div>
        <p className="text-lg md:text-xl">Description</p>
        <div className="w-full">
          <textarea
            className="w-full outline-none p-2 border border-gray-200 bg-gray-50"
            rows={4}
            placeholder="e.g. Write description"
            ref={description}></textarea>
        </div>
        <div className="flex items-center justify-center">
          <button className="button delete-button" onClick={handleDelete}>
            Delete
          </button>
          <button onClick={handleUploadVideo} className="button post-button">
            Post
          </button>
        </div>
      </div>

      <div className="columns is-mobile is-centered"></div>
      <div className="level">
        <div className="level-item"></div>
      </div>
    </section>
  )
}

export default UploadVideo
