export const SET_LOADING = "SET_VIDEO_LOADING"
export const SET_LOADING_OFF = "SET_VIDEO_LOADING_OFF"

export const GET_VIDEOS = "GET_VIDEOS"
export const LOAD_MORE_VIDEOS = "LOAD_MORE_VIDEOS"
export const SET_LOAD_MORE_VIDEOS = "SET_LOAD_MORE_VIDEOS"
export const GET_VIDEOS_FOR_UPDATE_URL = "GET_VIDEOS_FOR_UPDATE_URL"
export const GET_VIDEOS_REQUESTED = "GET_VIDEOS_REQUESTED"
export const UPLOAD_VIDEO = "UPLOAD_VIDEO"
export const UPLOAD_VIDEO_REQUESTED = "UPLOAD_VIDEO_REQUESTED"
export const UPDATE_VIDEOS_URLS = "UPDATE_VIDEOS_URLS"
export const LIKE_VIDEO = "LIKE_VIDEO";