import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LOGIN } from '../redux/actions/authActions'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Modal from '../components/Modal'

const AdminSignUP = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState(null)
  const authState = useSelector((state) => state.auth)

  const handleLogin = (_) => {
    // if (email && password)
    //   dispatch({
    //     type: LOGIN,
    //     payload: {
    //       email,
    //       password,
    //       callback: onLoginSuccess,
    //       failedCallback: onLoginFailure,
    //     },
    //   })
    // else onLoginFailure('Please enter email and password.')
    setShowModal(!showModal)
  }

  const onLoginSuccess = (async_) => {
    if (authState.user.user.is_admin) {
      history.push('/admin-dashboard')
    } else {
      history.push('/member-dashboard')
    }
  }

  // const onLoginFailure = (msg) => {
  //   setMessage(msg)
  //   setShowModal(true)
  // }

  return (
    <div className="w-full flex items-center justify-center md:min-h-screen bg-graybg ">
      {showModal ? (
        <>
          <div className="w-full md:w-1/2 bg-graybg md:bg-white md:pb-12 rounded-md px-4  md:px-0">
            <div className="hidden md:block ivod-box  text-transparent text-xs">
              Test
            </div>

            <div className="flex md:block flex-col justify-center   min-h-screen md:min-h-full md:pt-16">
              <img
                src={require(`../assets/img/logo.png`).default}
                alt=""
                className="block md:hidden mx-auto w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 object-contain"
              />
              <div className="w-full bg-white pb-4 rounded-md">
                <div
                  style={{ fontSize: '4px' }}
                  className="ivod-box block md:hidden">
                  .
                </div>

                <div className="w-full text-center my-5">
                  <div className="login-text ">Thank You</div>
                </div>
                <div className="flex items-center justify-center">
                  <div className="w-10/12 md:w-1/2">
                    Our team will review your request and get back to you.
                  </div>
                </div>

                <div className="flex items-center justify-center mt-4">
                  <div className="w-10/12 md:w-1/2">
                    <button
                      onClick={handleLogin}
                      className="button is-fullwidth ivod-login-button">
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full md:w-1/2 bg-white md:pb-12 rounded-md">
            <div className="hidden md:block ivod-box md:h-10 text-transparent text-xs">
              Test
            </div>

            <div className="flex md:block flex-col justify-center   min-h-screen md:min-h-full md:pt-16">
              <img
                src={require(`../assets/img/logo.png`).default}
                alt=""
                className="block md:hidden mx-auto w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 object-contain"
              />
              <div className="w-full text-center mb-5">
                <div className="login-text ">IVOD Sign In</div>
              </div>
              <div className="flex items-center justify-center">
                <div className="w-8/12 md:w-7/12">
                  <div className="field">
                    Email
                    <p className="control has-icons-left has-icons-right">
                      <input
                        onChange={(evt) => setEmail(evt.target.value)}
                        className="input"
                        type="email"
                        placeholder="Email"
                        value={email}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa fa-envelope"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center mt-2">
                <div className="w-8/12 md:w-7/12">
                  <button
                    onClick={handleLogin}
                    className="button is-fullwidth ivod-login-button">
                    Continue
                  </button>
                </div>
              </div>
              {/* <div className="columns is-centered mt-1">
            <div className="column is-half has-text-centered">
              <Link className="forgot-password-text" to="/forgot-password">
                Forgot Password
              </Link>
            </div>
          </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AdminSignUP
