import { combineReducers } from 'redux'

// Reducers
import { videoReducer } from './videoReducer'
import {
    authReducer
} from './authReducer';
import { sidebarReducer } from './sidebarReducer';
export default combineReducers({
    video: videoReducer,
    auth: authReducer,
    menu: sidebarReducer,
    // Here you can registering another reducers.
})