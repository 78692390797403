import {
    SET_LOADING,
    SET_LOADING_OFF,
    LOGIN,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    FORGOT_PASSWORD
} from '../actions/authActions';

const initialState = {
    loading: false,
    user: null,
    loginFailMessage: "",
}

export const authReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Set loading
        case SET_LOADING:
            return {
                ...state,
                loading: true
            };
        case SET_LOADING_OFF:
            return {
                ...state,
                loading: false
            }
        case LOGIN:
            console.log("login..")
            return { ...state, loading: true }
        case LOGIN_SUCCESS:
            return { ...state, user: payload }
        case LOGIN_FAILURE:
            return {
                ...state,
                loginFailMessage: payload
            }
        case LOGOUT:
            return {
                ...state,
                user: null
            }
        case FORGOT_PASSWORD:
            console.log("forgot pass")
            return {
                ...state
            }
        default:
            return state
    }
}