import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'

import { LOGOUT } from '../redux/actions/authActions'
import { SHOW_SIDEBAR } from '../redux/actions/sidebarActions'

const Logout = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleOnClick = async (_) => {
    await dispatch({ type: SHOW_SIDEBAR, payload: null })
    dispatch({ type: LOGOUT, success: handleSuccess })
  }

  useEffect(() => {
    handleOnClick()
  }, [])

  const handleSuccess = (_) => {
    history.push('/login')
  }

  return <></>
}

export default Logout
