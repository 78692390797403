import { REHYDRATE } from 'redux-persist';

import {
    SHOW_SIDEBAR
} from '../actions/sidebarActions';

const initialState = {
    visible: null,
}

export const sidebarReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        // Set loading

        case SHOW_SIDEBAR:
            return {
                ...state,
                visible: payload
            };
        case REHYDRATE:
            return {
                ...state
            }
        default:
            return state
    }
}