import { REHYDRATE } from 'redux-persist'

import {
  SET_LOADING,
  SET_LOADING_OFF,
  GET_VIDEOS,
  GET_VIDEOS_REQUESTED,
  UPLOAD_VIDEO,
  LIKE_VIDEO,
  UPDATE_VIDEOS_URLS,
  GET_VIDEOS_FOR_UPDATE_URL,
  SET_LOAD_MORE_VIDEOS,
} from '../actions/videoActions'

const initialState = {
  loading: false,
  videos: {
    results: [],
  },
}

export const videoReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Set loading
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SET_LOADING_OFF:
      return {
        ...state,
        loading: false,
      }
    case UPLOAD_VIDEO:
      //   console.log(payload)
      return { ...state }
    case GET_VIDEOS:
      return {
        ...state,
        loading: true,
      }
    case GET_VIDEOS_FOR_UPDATE_URL:
      return {
        ...state,
        loading: true,
      }
    case GET_VIDEOS_REQUESTED:
      return {
        ...state,
        loading: false,
        videos: payload,
      }
    case SET_LOAD_MORE_VIDEOS:

      let loadMoreVideos = payload.results.slice(
        state.videos.results.length - 1,
        payload.results.length - 1
      )

      return {
        ...state,
        loading: false,
        videos: {
          ...state.videos,
          results: [...state.videos.results, ...loadMoreVideos],
        },
      }

    case UPDATE_VIDEOS_URLS: {
      //   const clone = [ ...state.videos ]
      //   state.videos.filter((item,index) => item)
      let updatedVideos = []

      state.videos.results.map((v) => {
        payload.results.map((vu) => {
          if (vu.id === v.id) {
            let video = v
            video['video_file'] = vu.video_file
            updatedVideos.push(video)
          }
        })
      })

      // clone.videos
      return {
        ...state,
        loading: false,
        videos: { ...state.videos, results: updatedVideos },
      }
    }
    case LIKE_VIDEO:
      return {
        ...state,
        loading: true,
      }
    // case REHYDRATE:
    //     console.log("rehydrate for videos ", payload);
    //     return {
    //         ...state,
    //         videos: payload ? payload.video ? payload.video.videos : [] : []
    //     };
    default:
      return state
  }
}
