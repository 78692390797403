import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import logo from '../../assets/img/logo.png'
import { SHOW_SIDEBAR } from '../../redux/actions/sidebarActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Navbar = (props) => {
  const dispatch = useDispatch()
  const menu = useSelector((state) => state.menu)
  const authState = useSelector((state) => state.auth)

  const handleOnClick = (_) => {
      dispatch({ type: SHOW_SIDEBAR, payload: !menu.visible})
  }

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 768 && menu.visible)
  //       dispatch({ type: SHOW_SIDEBAR, payload: !menu.visible })
  //   }
  //   window.addEventListener('resize', handleResize)

  //   return () => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // }, [window])

  return (
    // <nav
    //   className="navbar overflow-hidden"
    //   role="navigation"
    //   aria-label="main navigation">
    //   <div className="navbar-brand">
    //     {/* add burger menu here */}
    //     <a
    //       onClick={handleOnClick}
    //       role="button"
    //       className="navbar-burger"
    //       aria-label="menu"
    //       aria-expanded="false"
    //       data-target="navbarBasicExample">
    //       <span aria-hidden="true"></span>
    //       <span aria-hidden="true"></span>
    //       <span aria-hidden="true"></span>
    //     </a>

    //     <a className="navbar-item mx-auto md:ml-6" href="">
    //       <figure className="image is-48x48">
    //         <img
    //           src={logo}
    //           alt="Bulma: Free, open source, and modern CSS framework based on Flexbox"
    //           width="112"
    //           height="28"
    //         />
    //       </figure>
    //     </a>
    //   </div>

    //   <div className="navbar-end ">
    //     <div class="navbar-item mr-6">
    //
    //     </div>
    //   </div>
    // </nav>
    <div className="w-full flex items-center justify-between h-16 md:h-20  sm:px-8 md:px-10 lg:px-12 xl:px-14">
      <div
        className="flex items-center lg:hidden ml-5 sm:ml-0"
        onClick={handleOnClick}>
        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          className="text-primary cursor-pointer"
        />
      </div>
      <div>
        <img
          src={logo}
          alt="Bulma: Free, open source, and modern CSS framework based on Flexbox"
          className="h-10 md:h-1/2"
        />
      </div>
      <div className="mr-5 sm:mr-0">
        {authState?.user?.user?.is_first_log_in && (
          <>
            {authState?.user?.user?.is_admin ? (
              <span className="font-semibold">Admin</span>
            ) : (
              <span className="font-semibold">Member</span>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default Navbar
