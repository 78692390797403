import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { API_HANDLER } from '../../../axios/axios'
import { ReactMaterialTable } from '../../../components/tables/tables'
import moment from 'moment'
import { Modal } from 'antd'
import DefaultModal from '../../../components/Modal/index'
export const Notifications = () => {
  const [TableData, setTableData] = useState([])
  const [modal, setmodal] = useState(false)
  const [notificatonDetail, setnotificatonDetail] = useState('')
  const [modalDelete, setmodalDelete] = useState(false)
  const [notificationResult, setnotificationResult] = useState('')

  const authState = useSelector((state) => state.auth)
  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let Response = await API_HANDLER(
      'GET',
      'https://young-glade-24121.botics.co/api/v1/notifications/?limit=1000',
      authState.user.key
    )
    console.log(Response.data.results)
    setTableData(Response.data.results)
  }

  var columns = [
    {
      field: 'Activities',
      title: 'Activites',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'left',
      },
      render: (rowData) => (
        <div className="text-left text-xs">
          {rowData.message.length > 20
            ? rowData.message.substring(0, 20).concat('...')
            : rowData.message}
        </div>
      ),
    },

    {
      title: 'Action',
      field: '_blank',
      cellStyle: {
        whiteSpace: 'nowrap',
        textAlign: 'center',
        margin: '0 auto',
      },

      render: (rowData) => (
        <div className="flex items-center justify-center mx-auto ">
          <button
            onClick={() => onOpenNotification(rowData)}
            className="text-primary  hover:text-gray-600 cursor-pointer font-semibold flex space-x-1">
            <span>View </span>
            <span className="hidden md:block">details</span>
          </button>
        </div>
      ),
    },
  ]

  const onOpenNotification = async (rowData) => {
    await setnotificatonDetail(rowData)
    setmodal(!modal)
  }

  const onDeleteNotification = async (id) => {
    let Response = await API_HANDLER(
      'DELETE',
      `https://young-glade-24121.botics.co/api/v1/users/${id}/`,
      authState.user.key
    )
    if (Response.data === '') {
      await setmodal(!modal)
      await setnotificationResult('Notification successfully deleted')
      setmodalDelete(!modalDelete)
      getData()
    }
    if (Response?.response?.data?.detail) {
      await setmodal(!modal)
      await setnotificationResult('Not found')
      setmodalDelete(!modalDelete)
    }
  }

  return (
    <div className="">
      <div className="w-full">
        <div className="w-full h-screen">
          <div className="flex items-center">
            <p className="text-lg md:text-xl font-semibold  ">Notifications</p>
          </div>
          <DefaultModal
            showModal={modalDelete}
            message={notificationResult}
            handleModalClose={() => setmodalDelete(!modalDelete)}
          />
          <Modal
            title="Notification"
            footer={null}
            visible={modal}
            destroyOnClose={true}
            // width="20%"
            centered={true}
            // bodyStyle={{ padding: 0, height: '550px', minHeight: '200px' }}
            onOk={() => setmodal(!modal)}
            onCancel={() => setmodal(!modal)}>
            <div>
              <span className="font-semibold">Action:</span>&nbsp;&nbsp;
              {notificatonDetail?.action}
            </div>
            <div>
              <span className="font-semibold">Created At:</span>&nbsp;&nbsp;
              {moment(notificatonDetail?.created_at).format('MM-DD-YYYY')}
            </div>
            <div>
              <span className="font-semibold">Updated At:</span>{' '}
              {moment(notificatonDetail?.updated_at).format('MM-DD-YYYY')}
            </div>
            <div className="font-semibold">Description:</div>
            <div className="text-sm">{notificatonDetail?.message}</div>
            <div className="flex items-center justify-center space-x-4 mt-5">
              <button
                onClick={() => onDeleteNotification(notificatonDetail.user)}
                className="font-semibold bg-primary text-white border hover:bg-red-500 hover:border-red-500 w-32 h-10 duration-1000 rounded-sm">
                Delete
              </button>
              <button
                onClick={() => setmodal(!modal)}
                className="font-semibold bg-white text-primary border border-primary hover:text-white hover:bg-primary hover:border-primary w-32 h-10 duration-1000 rounded-sm">
                Close
              </button>
            </div>
          </Modal>
          {/* <ReactMaterialTable
            columns={columns}
            data={TableData}
            exportBtn={true}
            searchbar={true}
            toolbar={false}
            fontSize="12px"
            pagging={true}
            id="table"
          /> */}
          <div className="flex flex-col ">
            <div className="flex h-10 ">
              <div className="w-10/12 text-left font-semibold hidden md:block pl-4">
                Activites
              </div>
              <div className="w-10/12 text-left font-semibold block  md:hidden">
                Activites
              </div>
              <div className="w-2/12 text-left font-semibold">Actions</div>
            </div>

            {TableData.map((item, index) => (
              <div
                key={index}
                className="flex items-center h-12 bg-white mb-0.5 -mx-4 md:-mx-0 px-4 py-2">
                <div style={{ fontSize: '10px' }} className="w-10/12 text-left">
                  <div className="w-10/12 flex items-center md:hidden">
                    {item.message.length > 40
                      ? item.message.substring(0, 40).concat('...')
                      : item.message}
                  </div>
                  <div className="w-10/12 hidden md:flex justify-between text-sm">
                    <span >
                      {item.message.length >= 80
                        ? item.message.substring(0, 80).concat('...')
                        : item.message}
                    </span>
                    <span>
                      {moment(item.created_at).startOf('hour').fromNow()}
                    </span>
                  </div>
                </div>
                <div className="w-2/12 text-left font-semibold">
                  <div className="flex items-center ">
                    <button
                      onClick={() => onOpenNotification(item)}
                      className="text-primary2  hover:text-gray-600 cursor-pointer font-semibold flex space-x-1">
                      <span className="text-xs sm:text-sm">View </span>
                      <span className="hidden md:block text-sm">details</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
