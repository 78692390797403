import moment from 'moment'
import React from 'react'
import { FaHeart, FaRegHeart } from 'react-icons/fa'

export const VideoComponent = ({ v, index, handleLikes }) => {
  return (
    <div className="column is-one-quarter-desktop is-half-tablet" key={index}>
      <div className="card p-3">
        <div className="card-image">
          <video
            className="w-full h-32"
            src={v.video_file}
            controls
            type="video/mp4"
          />
        </div>

        <footer className="flex ">
          <div className="w-full my-2">
            <div className="flex items-center justify-between w-full">
              <div>{v.title !== null ? v.title : v.user.name}</div>
              <span className="flex items-center">
                <span>{v.likes_count}</span>
                <span
                  className="ml-2 cursor-pointer"
                  onClick={(e) => handleLikes(e, v, index)}>
                  {v.is_liked ? (
                    <FaHeart color={'red'} />
                  ) : (
                    <FaRegHeart color={'red'} />
                  )}
                </span>
              </span>
            </div>
            <p className="is-size-7 like-container-time has-text-weight-light">
              {moment(v.created_at).fromNow()}
            </p>
            <p className="text-xs md:text-sm text-textgray">{v.description}</p>
          </div>
        </footer>
      </div>
    </div>
  )
}
