import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LOGIN } from '../redux/actions/authActions'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Modal from '../components/Modal'

const Login = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState(null)
  const authState = useSelector((state) => state.auth)
 
  const handleLogin = (_) => {
    if (email && password)
      dispatch({
        type: LOGIN,
        payload: {
          email,
          password,
          callback: onLoginSuccess,
          failedCallback: onLoginFailure,
        },
      })
    else onLoginFailure('Please enter email and password.')
  }

  const handleModalClose = (_) => {
    setShowModal(false)
  }

  const onLoginSuccess = (async_) => {
    if (authState.user.user.is_admin) {
      history.push('/admin-dashboard')
    } else {
      history.push('/member-dashboard')
    }
  }

  const onLoginFailure = (msg) => {
    setMessage(msg)
    setShowModal(true)
  }

  return (
    <div
      style={{ background: '#F9F9F9' }}
      className="w-full flex items-center justify-center md:min-h-screen  ">
      <Modal
        showModal={showModal}
        message={message}
        handleModalClose={handleModalClose}
      />
      <div className="w-full md:w-1/2 bg-white md:pb-12 rounded-md">
        <div className="hidden md:block ivod-box md:h-10 text-transparent">Test</div>

        <div className="flex md:block flex-col justify-center   min-h-screen md:min-h-full md:pt-16"> 
          <img src={require(`../assets/img/logo.png`).default} alt="" className="block md:hidden mx-auto w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 object-contain"/>   
          <div className="w-full text-center mb-5">
              <div className="login-text ">Member Login</div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-8/12 md:w-7/12">
              <div className="field">
                Email
                <p className="control has-icons-left has-icons-right">
                  <input
                    onChange={(evt) => setEmail(evt.target.value)}
                    className="input"
                    type="email"
                    placeholder="Email"
                    value={email}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope"></i>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-8/12 md:w-7/12">
              <div className="field">
                Password
                <p className="control has-icons-left has-icons-right">
                  <input
                    onChange={(evt) => setPassword(evt.target.value)}
                    className="input"
                    type="password"
                    placeholder="Password"
                    value={password}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-lock"></i>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mt-2">
            <div className="w-8/12 md:w-7/12">
              <button
                onClick={handleLogin}
                className="button is-fullwidth ivod-login-button">
                Continue
              </button>
            </div>
          </div>
          <div className="columns is-centered mt-1">
            <div className="column is-half has-text-centered">
              <Link className="forgot-password-text" to="/member-dashboard/forgot-password">
                Forgot Password
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
