import React from 'react';
import { useSelector } from 'react-redux';

import Sidebar from '../../components/Sidebar';

const Profile = props => {
    const auth = useSelector(state => state.auth);

    console.log("auth: ", auth);
    return (
        <div className="w-full h-screen"> 
            <div className="w-full md:w-1/2 lg:w-1/3">
                <p className="is-size-2">Profile</p>
                <div className="box">
                    <p className="is-size-6">Name: {auth.user.user.name}</p>
                    <p className="is-size-6">Email: {auth.user.user.email}</p>
                    {/* <div className="level mt-4">
                        <div className="level-left">
                            Password
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-full">
                            <input className="input" type="password" placeholder="******" />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Profile;