import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { PanelHandler } from '../layout/layout'
import Navbar from '../Navbar/index'
// const PrivateRoute = ({ children, ...rest }) => {

//     const authState = useSelector(state => state.auth);
//     console.log("private router", authState);
//     return (
//         <Route
//             {...rest}
//             render={({ location }) =>
//                 authState.user ? (
//                     children

//                 ) : (
//                     <Redirect to={{
//                         pathname: "/login",
//                         state: { from: location }
//                     }} />
//                 )
//             }
//         />
//     )
// }

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authState = useSelector((state) => state.auth)

  return (
    <Route
      {...rest}
      render={(props) => {
        return authState.user ? (
          <>
            <Navbar/>
            <PanelHandler>
              <Component />
            </PanelHandler>
          </>
        ) : (
          <Redirect to="/" />
        )
      }}
    />
  )
}

const PublicRoute = ({ children, ...rest }) => {
  const authState = useSelector((state) => state.auth)
  console.log('public router', authState)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authState.user ? (
          authState.user.user.is_admin ? (
            <Redirect
              to={{
                pathname: '/admin-dashboard',
                state: { from: location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/member-dashboard',
                state: { from: location },
              }}
            />
          )
        ) : (
          children
        )
      }
    />
  )
}

export { PrivateRoute, PublicRoute }
