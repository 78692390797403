import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { useHistory } from 'react-router'
import VideoRecorder from 'react-video-recorder'

import config from '../../config'
import Sidebar from '../../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRecycle, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { Modal } from 'antd'

const MediaRecorderCapture = (props) => {
  const [mediaRecorder, setMediaRecorder] = useState({})
  const [isRecording, setIsRecording] = useState(false)
  const [showRear, setShowRear] = useState(false)
  const recorderRef = useRef()
  const playerRef = useRef()
  const history = useHistory()
  const [availableDevices, setAvailableDevices] = useState([])
  const [time, settime] = useState()
  const [stopTimer, setstopTimer] = useState(false)
  const [Totalseconds, setTotalseconds] = useState(0)
  const [timer, settimer] = useState(0)
  var Totaltime = 0
  // var seconds = 0;
  /**
   * Initialize the MediaRecorder on component mount
   */

  // useEffect(() => {
  //   // console.log('called useEffect')
  //   // if (window.innerWidth < 800) {
  //   //   setShowRear(true)
  //   //   console.log('on')
  //   // }
  //   // else {
  //   //   setShowRear(false)
  //   //   console.log('off')
  //   // }
  // })

  // useEffect(() => {
  //   navigator.mediaDevices
  //     .enumerateDevices()
  //     .then(gotDevices)
  //     .then((ds) => setAvailableDevices(ds))
  //     .catch((e) => console.log(e))
  //   return () => {
  //     // stopVideo()

  //   }
  // }, [])

  // // const KillCamFunc = async () => {
  // //   let stream = await getStream()
  // //   const tracks = stream.getTracks()
  // //   tracks.forEach((track) => track.stop())
  // // }

  // useEffect(() => {
  //   console.log('reinitializing..')
  //   initializeMediaRecorder()
  // }, [facingMode])

  // /**
  //  * Upon MedaRecorder being set, monitor the following events
  //  */
  // useEffect(() => {
  //   let chunks = []

  //   mediaRecorder.ondataavailable = (e) => chunks.push(e.data)

  //   mediaRecorder.onstop = async (e) => {
  //     let blob = new Blob(chunks, { type: 'video/mp4' })
  //     chunks = []

  //     let url = (window.URL || window.webkitURL).createObjectURL(blob)
  //     handleVideoRecorder(blob)
  //     // localStorage.setItem('videoData',blob)
  //     // window.open('/upload')
  //     // window.close()
  //     // uploadVideo(blob);
  //     // setPlaybackPreview(url);
  //     // createDownloadLink(url);
  //   }

  //   mediaRecorder.onerror = (e) => {
  //     console.log('Error recording stream')
  //     console.log(e.error)
  //     alert(e.error)
  //   }

  //   console.log('MediaRecorder ready')
  // }, [mediaRecorder])

  // // const createDownloadLink = url => {
  // //   const link = <a href={url} download="Recording.mp4">Download</a>;
  // //   const linkContainer = document.getElementById('download-link');
  // //   ReactDOM.render(link, linkContainer);
  // // }

  // /**
  //  * Helper function to console out change in state of the MediaRecorder
  //  */
  // useEffect(() => {
  //   console.log(`MediaRecorder state: ${mediaRecorder.state}`)
  // }, [mediaRecorder.state])

  /**
   * Start recording the stream
   */
  const start = async () => {
    if (mediaRecorder.state === 'recording') return
    mediaRecorder.start()
    setIsRecording(true)
    onStartVideoTimer()
  }

  /**
   * Stop recording the stream
   */
  const stop = async () => {
    setstopTimer(true)

    if (mediaRecorder.state === 'inactive') return
    try {
      // mediaRecorder.stream.stop()
      mediaRecorder.stop()
    } catch (err) {
      console.log(
        'err for closing media recorder as stop function not present ',
        err
      )
    }

    clearInterval(timer)
    // settimer(0)
    // setTotalseconds(0)

    setIsRecording(false)
    // await initializeMediaRecorder()
  }

  /**
   * Set the playback player's source to the url of the newly recorderd stream
   * @param {string} url
   */
  const setPlaybackPreview = (url) => {
    if (!playerRef.current) return
    console.log(`Playback URL: ${url}`)
    playerRef.current.src = url
  }

  const gotDevices = (devices) => {
    return new Promise((resolve, reject) => {
      const availableVideoInputs = []
      devices.forEach((mediaDevice) => {
        if (mediaDevice.kind === 'videoinput') {
          availableVideoInputs.push({
            deviceId: mediaDevice.deviceId,
            label: mediaDevice.label,
          })
        }
      })

      if (availableVideoInputs.length > 0) {
        resolve(availableVideoInputs)
      } else {
        reject(new Error('ERR::NO_MEDIA_TO_STREAM'))
      }
    })
  }

  /**
   * Get a media device stream (webcam)
   */
  // const getStream = () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //         video: {
  //           facingMode: mobileCheck() ? { exact: 'environment' } : 'user',
  //         },
  //       })
  //       console.log('Stream fetched and rear camera? ', showRear)
  //       resolve(stream)
  //     } catch (err) {
  //       console.log('Error in fetching stream')
  //       alert(err)
  //       reject(err)
  //     }
  //   })
  // }

  /**
   * Set the live stream retrieved from the media device
   * to the designated player to preview
   * @param {object} stream
   */
  const setRecordingStreamPreview = (stream) => {
    if (!recorderRef.current) return
    recorderRef.current.srcObject = stream
    console.log('recordref ', recorderRef.current.srcObject)
  }

  /**
   * Create MediaRecorder object from a given stream
   * @param {object} stream
   */
  const createMediaRecorder = (stream) => {
    return new Promise((resolve, reject) => {
      try {
        // var options = {mimeType: 'video/webm; codecs=vp9'}
        const mediaRecorder = new MediaRecorder(stream)
        console.log('New MediaRecorder created')
        resolve(mediaRecorder)
      } catch (err) {
        console.log('Error in creating new MediaRecorder')
        alert(err)
        reject(err)
      }
    })
  }

  /**
   * Initialize MediaRecorder
   */
  // const initializeMediaRecorder = async () => {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       let stream = await getStream()
  //       const tracks = stream.getTracks()
  //       tracks.forEach((track) => track.stop())
  //       stream = await getStream()

  //       setRecordingStreamPreview(stream)
  //       const mediaRecorder = await createMediaRecorder(stream)
  //       setMediaRecorder(mediaRecorder)
  //       resolve(mediaRecorder)
  //     } catch (err) {
  //       console.log(
  //         'Error in initializing MediaRecorder of fetching media devices stream'
  //       )
  //       alert(err)

  //       reject(err)
  //     }
  //   })
  // }

  const handleFileChange = (evt) => {
    props.handleFileChange(evt)
  }

  const handleVideoRecorder = (blob) => {
    props.handleVideoRecorder(blob)
  }

  const handleRearCamera = async (_) => {
    // await stop();
    // history.push("/back-camera");
    setShowRear(true)
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60))

    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)

    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    }
    return obj
  }

  const onStartVideoTimer = async () => {
    console.log(timer, Totalseconds)
    if (timer === 0 && Totalseconds < 1) {
      await setstopTimer(false)
      settimer(setInterval(countDown, 1000))
    }
  }
  // console.log(timer)

  const mobileCheck = (_) => {
    let check = false
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
  }

  // const mobileCheck = (_) => {
  //   let check = false
  //   ;(function (a) {
  //     if (
  //       /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
  //         a
  //       ) ||
  //       /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
  //         a.substr(0, 4)
  //       )
  //     )
  //       check = true
  //   })(navigator.userAgent || navigator.vendor || window.opera)
  //   return check
  // }



  const countDown = async () => {
    let Ts = Totaltime++
    settime(secondsToTime(Ts))
    if (stopTimer && Totaltime > 0) {
      clearInterval(timer)
      settimer('')
    }
  }

  const [Playing, setPlaying] = useState(false)
  const [streamValue, setstreamValue] = useState('')
  // useEffect(() => {
  //   // onStartStream()
  // }, [cfacingMode])

  const clearVideo = async () => {
    let videoT = await document.getElementsByClassName('app__videoFeed')[0]
    console.log(videoT)
    if (videoT) {
      videoT.srcObject?.getTracks()[0].stop()
      videoT.srcObject?.getTracks()[1].stop()
    }
  }

  // const onStartStream = async () => {
  //   let Newvideo = await document.getElementsByClassName('app__videoFeed')[0]
  //   if (Newvideo) {
  //     Newvideo.srcObject?.getTracks()[0].stop()
  //     Newvideo.srcObject?.getTracks()[1].stop()
  //     console.log("hghfh")
  //     // video.srcObject = stream
  //   }
  //   const stream = await navigator.mediaDevices.getUserMedia({
  //     audio: true,
  //     video: {
  //       facingMode:
  //         mobileCheck() && cfacingMode === 'environment'
  //           ? { exact: 'environment' }
  //           : 'user',
  //     },
  //   })
  //   let video = document.getElementsByClassName('app__videoFeed')[0]
  //   if (video) {
  //     // video.srcObject?.getTracks()[0].stop()
  //     // video.srcObject?.getTracks()[1].stop()
  //     video.srcObject = stream
  //   }
  //   setstreamValue(stream)
  // }

  const startVideo = async (camera) => {
    setPlaying(true)
    setmodal(false)
    onStartVideoTimer()
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: {
        facingMode:
          mobileCheck() && camera === 'environment'
            ? { exact: 'environment' }
            : 'user',
      },
    })
    let video = document.getElementsByClassName('app__videoFeed')[0]
    if (video) {
      video.srcObject = stream
    }
    const mediaRecorder = await createMediaRecorder(stream)
    setMediaRecorder(mediaRecorder)
    mediaRecorder.start()
  }

  const stopVideo = () => {
    setPlaying(false)
    let video = document.getElementsByClassName('app__videoFeed')[0]
    video.srcObject.getTracks()[0].stop()
    video.srcObject.getTracks()[1].stop()
    mediaRecorder.stop()
    let chunks = []

    mediaRecorder.ondataavailable = (e) => chunks.push(e.data)

    mediaRecorder.onstop = async (e) => {
      let blob = new Blob(chunks, { type: 'video/mp4' })
      chunks = []

      let url = (window.URL || window.webkitURL).createObjectURL(blob)
      handleVideoRecorder(blob)
    }

    mediaRecorder.onerror = (e) => {
      console.log('Error recording stream')
      console.log(e.error)
      alert(e.error)
    }
  }

  const [Orientation, setOrientation] = useState('Portrait')

  useEffect(() => {
    window.onresize = function () {
      getOrientation()
    }
  }, [])

  function getOrientation() {
    var orientation =
      window.innerWidth > window.innerHeight ? 'Landscape' : 'Portrait'
    setOrientation(orientation)
    return orientation
  }
  // const onChangeCamera = async (camera) => {
  //   if(cfacingMode === camera)return alert(`Currently already using ${camera}`);

  //   setcfacingMode(cfacingMode === camera ? 'environment' : 'user')
  // }

  const [modal, setmodal] = useState(false)
  return (
    <div className={Orientation === 'Landscape'? '' : 'min-h-screen'}>
      <div
        className={
          Orientation === 'Landscape'
            ? `w-1/2 mx-auto relative h-32 bg-gray-900 overflow-hidden`
            : `w-full md:w-1/2 mx-auto relative lg:h-96 bg-gray-900 overflow-hidden`
        }>
        <video
          className="w-full app__videoFeed left-0"
          ref={recorderRef}
          autoPlay
          playsInline
          muted
        />
        <Modal
          title="Chose Camera"
          footer={null}
          visible={modal}
          destroyOnClose={true}
          // width="20%"
          centered={true}
          // bodyStyle={{ padding: 0, height: '550px', minHeight: '200px' }}
          onOk={() => setmodal(!modal)}
          onCancel={() => setmodal(!modal)}>
          <div className="flex items-center justify-center space-x-4 mt-5">
            <button
              onClick={() => startVideo('user')}
              className="font-semibold bg-primary text-white border hover:bg-red-500 hover:border-red-500 w-32 h-10 duration-1000 rounded-sm">
              Front Camera
            </button>
            <button
              onClick={() => startVideo('environment')}
              className="font-semibold bg-white text-primary border border-primary hover:text-white hover:bg-primary hover:border-primary w-32 h-10 duration-1000 rounded-sm">
              Back Camera
            </button>
          </div>
        </Modal>
        {Playing && (
          <div
            className="w-full mx-auto h-12 absolute bottom-0 left-0 text-white"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}>
            {time?.h ? time.h : '00'}:{time?.m ? time.m : '00'}:
            {time?.s ? time.s : '00'}
          </div>
        )}
      </div>
      <div className="level is-mobile mt-5">
        <div className="level-item text-center">
          <div className="player-uttons">
            <button
              className="button is-rounded is-danger"
              onClick={Playing ? stopVideo : () => setmodal(!modal)}>
              <span className="icon">
                {Playing ? (
                  <i className="fa fa-stop"></i>
                ) : (
                  <i className="fa fa-video-camera"></i>
                )}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="level is-mobile">
        <div className="level-item has-text-centered">
          <div className="file is-primary">
            <label className="file-label">
              <input
                onChange={handleFileChange}
                className="file-input"
                type="file"
                name="video"
                accept="video/mp4,video/x-m4v,video/*"
              />
              <span className="file-cta">
                <span className="file-icon">
                  <i className="fa fa-cloud-upload"></i>
                </span>
                <span className="file-label">Upload Video</span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaRecorderCapture
