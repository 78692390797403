import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
//import { FaRegHeart, FaHeart } from 'react-icons/fa'
//import moment from 'moment'

//import Sidebar from '../../components/Sidebar'
import {
  GET_VIDEOS,
  GET_VIDEOS_FOR_UPDATE_URL,
  //LIKE_VIDEO,
  LOAD_MORE_VIDEOS,
  //UPDATE_VIDEOS_URLS,
} from '../../redux/actions/videoActions'
import { API_HANDLER } from '../../axios/axios'
import { VideoComponent } from './VideoComponent'

const MemberDashboard = (props) => {
  const history = useHistory()
  const [videos, setVideos] = useState([])
  const [LoadMoreVideos, setLoadMoreVideos] = useState(6)
  // get videos state
  const videoState = useSelector((state) => state.video)
  const authState = useSelector((state) => state.auth)

  const searchRef = useRef()

  const dispatch = useDispatch()

  const handleAddVideo = (_) => {
    history.push('/record')
  }

  useEffect(() => {
    dispatch({
      type: GET_VIDEOS,
      payload: { user: authState.user, limit: LoadMoreVideos },
    })

    // setInterval(() => {
    //   dispatch({
    //     type: GET_VIDEOS_FOR_UPDATE_URL,
    //     payload: { user: authState.user, limit: LoadMoreVideos },
    //   })
    // }, 110000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user])

  const handleLikes = async (evt, video, index) => {
    evt.preventDefault()
    const clone = [...videos]
    clone[index].is_liked = await !clone[index].is_liked
    console.log(clone[index].is_liked)
    clone[index].is_liked
      ? (clone[index].likes_count = clone[index].likes_count + 1)
      : (clone[index].likes_count = clone[index].likes_count - 1)
    setVideos(clone)
    console.log(video.id, authState.user.user.id)
    let Response = await API_HANDLER(
      'POST',
      `https://young-glade-24121.botics.co/api/v1/video-likes/`,
      authState.user.key,
      {
        video: video.id,
        user: authState.user.user.id,
      }
    )
    console.log(Response)
    // return
    // dispatch({
    //   type: LIKE_VIDEO,
    //   payload: { video: video.id, user: authState.user },
    // })
  }

  useEffect(() => {
    dispatch({
      type: LOAD_MORE_VIDEOS,
      payload: { user: authState.user, limit: LoadMoreVideos },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user, LoadMoreVideos])

  const LoadMore = () => {
    setLoadMoreVideos((previous) => previous + 6)
  }

  const getFilteredData = async (e) => {
    // console.log(LoadMoreVideos)
    if (e.key !== 'Enter') return
    let Response = await API_HANDLER(
      'GET',
      `https://young-glade-24121.botics.co/api/v1/videos/?user__id=${authState.user.user.id}&search=${searchRef.current.value}&limit=${LoadMoreVideos}`,
      authState.user.key
    )
    // console.log(Response?.data, 'test 2323')
    setVideos(Response?.data?.results)
  }

  return (
    <div
      className="columns mt-5 min-h-screen"
      style={{ background: '#F9F9F9' }}>
      <div className="column ml-5">
        <div className="is-flex is-align-items-center	">
          <p className="has-text-weight-bold is-size-4 ml-2">Videos</p>
          <div className="field ml-6">
            <p className="control has-icons-left">
              <input
                className="input is-small"
                type="text"
                placeholder="Add text"
                ref={searchRef}
                onKeyDown={getFilteredData}
              />
              <span className="icon is-medium is-left">
                <i className="fa fa-search "></i>
              </span>
            </p>
          </div>
        </div>
        <div className="column">
          <div className="columns is-mobile">
            <div className="column is-flex is-10-desktop is-7-mobile">
              <p className="has-text-weight-semibold">Category: &nbsp;</p>{' '}
              <span>
                {authState?.user?.user?.category_name
                  ? authState?.user?.user?.category_name
                  : 'N/A'}
              </span>
            </div>
            <div className="column">
              <button
                className="button post-button px-5"
                onClick={handleAddVideo}>
                Add video
              </button>
            </div>
          </div>
          <div className="columns">
            <div className="column px-2 py-5" style={{ background: 'white' }}>
              <div className="columns is-multiline">
                {videoState.videos.results &&
                  videoState.videos.results?.length > 0 &&
                  videoState.videos.results.map((v, index) => (
                    <VideoComponent
                      v={v}
                      index={index}
                      handleLikes={handleLikes}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="box has-text-centered">
            {videoState.videos.results &&
            videoState.videos.results?.length > 5 ? (
              <button className="button is-normal px-5" onClick={LoadMore}>
                Load more
              </button>
            ) : (
              <div>No more videos available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default MemberDashboard
