import React from 'react';
import { useLocation } from "react-router-dom";
import Sidebar from '../components/Sidebar';

const NoMatch = props => {
    const location = useLocation();

    console.log("nomatch loca state", location.state);
    return (
        <>
            Not found
            <Sidebar />
        </>
    );
}

export default NoMatch;