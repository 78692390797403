import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { API_HANDLER } from '../../../axios/axios'
import Sidebar from '../../../components/Sidebar/index'
export const AddUser = () => {
  const history = useHistory()
  const authState = useSelector((state) => state.auth)
  const [categories, setcategories] = useState()
  const [Error, setError] = useState('')
  const [modal, setmodal] = useState(false)
  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let Response = await API_HANDLER(
      'GET',
      'https://young-glade-24121.botics.co/api/v1/categories/?limit=1000&offset=0',
      authState.user.key
    )

    setcategories(Response?.data?.results)
  }
  const onSubmitForm = async (e) => {
    e.preventDefault()

    let data = {}
    for (var i = 0; i < e.target.length - 1; i++) {
      data[e.target[i].name] = e.target[i].value
    }
    console.log({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
      category: parseInt(data.category),
    })
    let Response = await API_HANDLER(
      'POST',
      'https://young-glade-24121.botics.co/api/v1/users/',
      authState.user.key,
      {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: data.password,
        category: parseInt(data.category),
      }
    )
    console.log(Response?.response?.data?.email)
    if (Response?.status === 201) {
      await setError('Success: User successfully added.')
      await setmodal(!modal)
    }
    if (Response?.response?.data?.email) {
      await setError(`Error: ${Response?.response?.data?.email}`)
      setmodal(!modal)
    }
  }

  return (
    <div className="overflow-hidden">
      <div className="overflow-hidden w-full mx-auto">
        <div className="column h-screen">
          <div className="is-flex is-align-items-center">
            <p className="text-lg md:text-xl font-semibold  ">Add User</p>
          </div>
          {!modal ? (
            <form
              onSubmit={onSubmitForm}
              className="flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl ">
              <div className="flex flex-col">
                <label htmlFor="FirstName">First name</label>
                <input
                  id="FirstName"
                  name="first_name"
                  placeholder="Add First Name"
                  autoComplete="off"
                  className="inputStyle"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="LastName">Last name</label>
                <input
                  id="LastName"
                  name="last_name"
                  placeholder="Add Last Name"
                  autoComplete="off"
                  required
                  className="inputStyle"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="Email">Email</label>
                <input
                  id="Email"
                  name="email"
                  type="email"
                  placeholder="Add Email"
                  autoComplete="off"
                  required
                  className="inputStyle"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="password">Password</label>
                <input
                  id="Pasasword"
                  name="password"
                  type="password"
                  required
                  placeholder="●●●●●●●●"
                  autoComplete="off"
                  className="inputStyle"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="category">Category</label>
                <select required name="category" className="inputStyle">
                  {categories?.map((item, index) => {
                    
                    return (
                      <option
                        key={index}
                        className="md:h-5 text-xs sm:text-base md:text-lg"
                        value={item.id}>
                        {item.name}
                      </option>
                    )
                  })}
                </select>
              </div>

              <button
                type="submit"
                className={`bg-primary border text-white hover:bg-white hover:border-primary hover:text-primary hover: text-xs sm:text-sm md:text-base duration-1000 w-full py-1 md:py-2 rounded-sm flex items-center justify-center`}>
                Add User
              </button>
            </form>
          ) : (
            <div className="flex flex-col  space-y-4 w-full md:w-1/3 rounded-sm bg-white p-8 shadow-xl ">
              <div className="text-lg">Notification:</div>
              <div>{Error}</div>
              <div className="flex flex-col items-center justify-center space-y-2 z-10">
                {Error === 'Success: User successfully added.' && (
                  <button
                    onClick={() => history.push('/admin-dashboard/users-list')}
                    className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm  rounded-md `}>
                    Next
                  </button>
                )}

                <button
                  onClick={() => setmodal(!modal)}
                  className={`text-primary bg-white border border-primary hover:bg-primary duration-1000 hover:text-white w-1/2 flex items-center justify-center h-8 text-sm rounded-md `}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
