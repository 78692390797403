import React from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { ReactComponent as TVIcon } from "../../assets/icons/tv.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/user.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";

export const MemberSidebarData = [
    {
        title: "Videos",
        path: "/member-dashboard",
        icon: <TVIcon />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        // subNav: [
        //     {
        //         title: "Our Aim",
        //         path: "/about-us/aim",
        //         icon: <IoIcons.IoIosPaper />,
        //     },
        //     {
        //         title: "Our Vision",
        //         path: "/about-us/vision",
        //         icon: <IoIcons.IoIosPaper />,
        //     },
        // ],
    },
    {
        title: "Profile",
        path: "/member-dashboard/profile",
        icon: <ProfileIcon />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        // subNav: [
        //     {
        //         title: "Service 1",
        //         path: "/services/services1",
        //         icon: <IoIcons.IoIosPaper />,
        //         cName: "sub-nav",
        //     },
        //     {
        //         title: "Service 2",
        //         path: "/services/services2",
        //         icon: <IoIcons.IoIosPaper />,
        //         cName: "sub-nav",
        //     },
        //     {
        //         title: "Service 3",
        //         path: "/services/services3",
        //         icon: <IoIcons.IoIosPaper />,
        //     },
        // ],
    },
    {
        title: "Logout",
        path: "/logout",
        icon: <LogoutIcon />,
    },
    // {
    //     title: "Events",
    //     path: "/events",
    //     icon: <FaIcons.FaEnvelopeOpenText />,

    //     iconClosed: <RiIcons.RiArrowDownSFill />,
    //     iconOpened: <RiIcons.RiArrowUpSFill />,

    //     subNav: [
    //         {
    //             title: "Event 1",
    //             path: "/events/events1",
    //             icon: <IoIcons.IoIosPaper />,
    //         },
    //         {
    //             title: "Event 2",
    //             path: "/events/events2",
    //             icon: <IoIcons.IoIosPaper />,
    //         },
    //     ],
    // },
    // {
    //     title: "Support",
    //     path: "/support",
    //     icon: <IoIcons.IoMdHelpCircle />,
    // },
];
export const AdminSidebarData = [
    {
        title: "Videos",
        path: "/admin-dashboard" ,
        icon: <TVIcon />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
    },
    {
        title: "Admin",
        path: '/add-user',
        icon: <ProfileIcon />,
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                title: "Add User",
                path: "/admin-dashboard/add-user",
                cName: "sub-nav",
            },
            {
                title: "Users list",
                path: "/admin-dashboard/users-list",
                cName: "sub-nav",
            },
            {
                title: "Categories",
                path: "/admin-dashboard/categories",
                cName: "sub-nav",
            },
            {
                title: "Notifications",
                path: "/admin-dashboard/notifications",
                cName: "sub-nav",
            },
        ],
    },
    {
        title: "Logout",
        path: "/logout",
        icon: <LogoutIcon />,
    },
];